const V1_URI = "/api/v1";
const BACKEND_URI = "";

import { ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, Config, SaveAppRequest, SaveLogRequest,  DataResponse, DataResponseOrError } from "./models";
import { useLogin, appServicesToken } from "../authConfig";

export function getHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    // If using login and not using app services, add the id token of the logged in account as the authorization
    if (useLogin && appServicesToken == null) {
        if (idToken) {
            headers["Authorization"] = `Bearer ${idToken}`;
        }
    }

    headers["x-api-key"] = "test";

    return headers;
}

export async function askApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${V1_URI}/testing/ask`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function getScoresApi(idToken: string | undefined): Promise<DataResponse> {
    const response = await fetch(`${V1_URI}/testing/scores`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    const parsedResponse: DataResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as DataResponse;
}

export async function getAllyLogsApi(idToken: string | undefined): Promise<DataResponse> {
    const response = await fetch(`${V1_URI}/testing/ally_logs`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    const parsedResponse: DataResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as DataResponse;
}

export async function articleApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${V1_URI}/testing/article`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function configApi(idToken: string | undefined): Promise<Config> {
    const response = await fetch(`${BACKEND_URI}/config`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    return (await response.json()) as Config;
}

export async function chatApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${V1_URI}/testing/chat`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export async function infoApi(idToken: string | undefined): Promise<Response> {
    return await fetch(`${V1_URI}/testing/info`, {
        method: "GET",
        headers: getHeaders(idToken)
    });
}

export async function saveApi(request: SaveAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${V1_URI}/testing/save`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export async function saveLogApi(request: SaveLogRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${V1_URI}/testing/log`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export function getCitationFilePath(citation: string): string {
    return `${V1_URI}/testing/content/${citation}`;
}
