export const enum RetrievalMode {
    Hybrid = "hybrid",
    Vectors = "vectors",
    Text = "text"
}

export const enum SelectedIndex {
    ClientEd = "client-ed-index-bge-chunked",
    Sofie = "sofie-index"
}

export const enum GPT4VInput {
    TextAndImages = "textAndImages",
    Images = "images",
    Texts = "texts"
}

export const enum VectorFieldOptions {
    ContentEmbedding = "contentVector",
    TitleEmbedding = "titleVector",
    Both = "both"
}

export type ChatAppRequestOverrides = {
    retrieval_mode?: RetrievalMode;
    selected_index?: SelectedIndex;
    semantic_ranker?: boolean;
    semantic_captions?: boolean;
    exclude_category?: string;
    top?: number;
    temperature?: number;
    minimum_search_score?: number;
    minimum_reranker_score?: number;
    prompt_template?: string;
    prompt_template_prefix?: string;
    prompt_template_suffix?: string;
    suggest_followup_questions?: boolean;
    use_oid_security_filter?: boolean;
    use_groups_security_filter?: boolean;
    use_gpt4v?: boolean;
    gpt4v_input?: GPT4VInput;
    vector_fields?: VectorFieldOptions[];
};

export type ResponseMessage = {
    content: string;
    role: string;
};

export type Thoughts = {
    title: string;
    description: any; // It can be any output from the api
    props?: { [key: string]: string };
};

export type ResponseContext = {
    data_points: string[];
    followup_questions: string[] | null;
    thoughts: Thoughts[];
};

export type ResponseChoice = {
    index: number;
    message: ResponseMessage;
    context: ResponseContext;
    session_state: any;
};

export type ChatAppResponseOrError = {
    choices?: ResponseChoice[];
    error?: string;
};

export type InfoAppResponseOrError = {
    model?: string;
    index?: any;
    error?: string;
    indices?: any;
};

export type InfoAppResponse = {
    model: string;
    index: any;
    questions: string[];
    indices: any;
};

export type ChatAppResponse = {
    choices: ResponseChoice[];
};

export type ChatAppRequestContext = {
    overrides?: ChatAppRequestOverrides;
};

export type ChatAppRequest = {
    messages: ResponseMessage[];
    chat_type: string;
    model: string;
    index: string;
    context: ChatAppRequestContext;
    stream?: boolean;
    session_state: any;
};

export type SaveAppRequest = {
    user: string;
    score: number;
    medical_accuracy: number;
    readability: number;
    completeness_of_answer: number;
    score_explanation: string;
    question: string;
    answer: string;
    edited_answer: string;
    model: string;
    index: string;
    document_ids: number[];
};

export type SaveLogRequest = {
    start: string;
    user: string;
    chat_type: string;
    question: string;
    response: string;
    sources: object[];
    tokens_input: number;
    tokens_output: number;
    model: string;
    prompt: string;
    guid: string;
};

export type Config = {
    showGPT4VOptions: boolean;
    showSemanticRankerOption: boolean;
    showVectorOption: boolean;
};

export type DataResponseOrError = {
    data?: Scores[] | AllyLogs[];
    error?: string;
};

export type DataResponse = {
    data: Scores[] | AllyLogs[];
};

export type Scores = {
    question: string;
    answer: string;
    document_ids: number[];
    model: string;
    medical_accuracy: number;
    completeness: number;
    readability: number;
    score: number;
    explanation: string;
    created_at: Date;
};

export type AllyLogs = {
    id: number
    user: string;
    created_at: Date;
};